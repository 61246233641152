import { message } from 'antd';
import { action, decorate, observable } from 'mobx';
import { LicenseRequest, OrganizationRequest, ProjectRequest } from '../requests';

class OrganizationStore {
    isLoading = false
    editMode = false
    projectList = []
    organizationActiveTab = '1'
    paramsOrganizationDetail = ""
    showOrganization = false
    modalCreateProject = false
    modalEditProject = false
    statusProject = ''
    forceReload = 0
    currentOrganizationView = {}
    orgBelongUserList = []
    projectData = {}
    showInviteUserToOrganizationModal = false
    showAddLicenseToOrganizationModal = false
    organizationRoles = []

    organizations = []
    currentOrganizations = {}
    isRiderect = false
    isLoadingOrganization = false
    organizationViewEditing = {}
    organizationUserRoleViewEditing = {}
    createMode = false
    organizationUserRoles = []
    licenses = []
    licenseSelected = []
    loadingTable = false
    isReload = false
    logoUpload = {}
    isModalVisible = false
    invitationDetail = {}
    orgUserBelongList = []
    organizationTemplates = []

    getOrganizationTemplates = payload => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationTemplates(payload)
                .then((res) => {
                    this.organizationTemplates = res.data.filter(c => !c.isDeleted)
                    resolve(res.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    setInvitationDetail = payload => {
        this.invitationDetail = payload
    }

    clearInvitationDetail = () => {
        this.invitationDetail = {}
    }

    setIsModalVisible = status => {
        this.isModalVisible = status
    }

    setIsModalVisible = status => {
        this.isModalVisible = status
    }

    setIsReload = (stt) => {
        this.isReload = stt;
    }
    setCurrentLogoUpload = (payload) => {
        this.logoUpload = payload;
    }
    setOrganizationUserRoleViewEditing = (payload) => {
        this.organizationUserRoleViewEditing = payload;
    }
    setLoadingTable = (status) => {
        this.loadingTable = status;
    }
    getLicenseById = (id) => {
        LicenseRequest.getLicenseById(id)
            .then(res => {
                this.licenseSelected = res.data
            })
    }

    resetStatus = () => {
        this.setLoadingProgress(false)
        this.setCreateMode(false)
        this.setEditMode(false)
        this.setRiderect(false)
    }


    getLicense = () => {
        LicenseRequest.getLicense()
            .then(res => {
                this.licenses = res.data.map(item => {
                    if (item.organization && item.organization.id) {
                        return {
                            ...item,
                            organization: item.organization.id,
                        }
                    } else {
                        return item;
                    }
                })
            })
            .catch(err => console.log("Lisence", err))
    }

    getOrganizationUserRole = () => {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationUserRole()
                .then(res => {
                    this.organizationUserRoles = res.data.map(item => {
                        return {
                            ...item,
                            organization: item.organization && item.organization.id !== null ? item.organization.id : item.organization,
                            user: item.user && item.user.id !== null ? item.user.id : item.user,
                            organizationrole: item.organizationrole && item.organizationrole.id !== null ? item.organizationrole.id : item.organizationrole,
                        }
                    })
                })
                .then(res => {
                    resolve();
                })
                .catch(err => {
                    reject(err)
                })
        })

    }

    setCurrentOrganizations = (payload) => {
        this.currentOrganizations = payload;
    }
    setLoadingOrganization = (status) => {
        this.isLoadingOrganization = status
    }

    setForceReload = (number) => {
        this.forceReload = number + this.forceReload
    }

    async getOrganization() {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganization().then(res => {
                resolve(res.data);
                this.organizations = [...res.data]
            })
                .catch(err => {
                    reject(err)
                    console.log(err)
                })
        })


    }

    setCreateMode(state) {
        this.createMode = state
    }

    getOrganizationBelongUser = () => {
        return OrganizationRequest.getOrganizationBelongUser().then((response) => {
            this.orgBelongUserList = [...response.data];
        })
    }

    clearOrganizationBelongUser = () => {
        this.orgBelongUserList = []
    }

    setLoadingProgress = (isLoading) => {
        this.isLoading = isLoading;
    }

    setEditMode(state) {
        this.editMode = state
    }

    setModalCreateProject(state) {
        this.modalCreateProject = state
    }

    setModalEditProject(state) {
        this.modalEditProject = state
    }

    setActiveTab(key) {
        this.organizationActiveTab = key
    }

    setParamsOrganizationDetail(paramId) {
        this.paramsOrganizationDetail = paramId
    }

    setShowOrganization(isShow) {
        this.showOrganization = isShow
    }


    getCurrentOrganizationDetail(id) {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationById(id)
                .then(response => {
                    this.currentOrganizationView = response.data
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }
    getCurrentOrganizationById(id, isForce) {
        return new Promise((resolve, reject) => {
            if (!isForce && this.currentOrganizations.id === id) {
                resolve(this.currentOrganizations)
            }
            OrganizationRequest.getOrganizationById(id)
                .then(response => {
                    this.currentOrganizations = response.data
                    resolve(this.currentOrganizations)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    clearCurrentOrganizationView = () => {
        this.currentOrganizationView = {}
    }

    setProjectData = (data) => {
        this.projectData = data
    }

    clearProjectData = () => {
        this.projectData = {};
    }

    getOrganizationRoles = () => {
        return OrganizationRequest.getOrganizationRoles()
            .then(response => {
                this.organizationRoles = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }

    clearOrganizationRoles = () => {
        this.organizationRoles = [];
    }

   
    async getOrganizationUserBelong() {
        return new Promise((resolve, reject) => {
            OrganizationRequest.getOrganizationUserBelong()
                .then(response => {
                    let v = response.data.filter(elm => elm.organization && elm.organizationrole && elm.user && elm.email === elm.user.email && elm.inviteStatus === "accepted")
                    let res = []
                    if (v.length > 0) {
                        res = v.map(org => org.organization)
                    }
                    this.orgUserBelongList = res
                    resolve(res)
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })
        })
    }

    clearOrgUserBelongList = () => {
        this.orgUserBelongList = []
    }

    clearOrganizationTemplate = () => {
        this.organizationTemplates = []
    }

    setOrganizationTemplate = (data) => {
        this.organizationTemplates = data
    }


}

decorate(OrganizationStore, {
    isLoading: observable,
    setLoadingProgress: action,
    editMode: observable,
    setEditMode: action,
    projectList: observable,
    setActiveTab: action,
    organizationActiveTab: observable,
    currentOrganizationView: observable,
    getCurrentOrganizationDetail: action,
    clearCurrentOrganizationView: action,
    orgBelongUserList: observable,
    getOrganizationBelongUser: action,
    clearOrganizationBelongUser: action,
    updateOrganizationInfo: action,
    updateOrganizationQuota: action,
    createProject: action,
    updateProject: action,
    getProjectById: action,
    projectData: observable,
    setProjectData: action,
    clearProjectData: action,
    removeUserFromProject: action,
    deleteProject: action,
    showInviteUserToOrganizationModal: observable,
    showAddLicenseToOrganizationModal: observable,
    toggleShowInviteUserToOrganizationModal: action,
    toggleShowAddLicenseToOrganizationModal: action,
    getOrganizationRoles: action,
    organizationRoles: observable,
    clearOrganizationRoles: action,
    sendInvite: action,
    removeUserFromOrganization: action,
    acceptInviteToOrganization: action,
    getOrganization: action,
    organizations: observable,
    setCurrentOrganizations: action,
    currentOrganizations: observable,
    customUpdateOrganization: action,
    isRiderect: observable,
    setRiderect: action,
    isLoadingOrganization: observable,
    setLoadingOrganization: action,
    createMode: observable,
    setCreateMode: action,
    getOrganizationUserRole: action,
    organizationUserRoles: observable,
    licenses: observable,
    getLicense: action,
    getLicenseById: action,
    licenseSelected: observable,
    setLoadingTable: action,
    loadingTable: observable,
    setOrganizationUserRoleViewEditing: action,
    organizationUserRoleViewEditing: observable,
    deleteOrganizationUserRole: action,
    setIsReload: action,
    isReload: observable,
    createOrganizationUserRole: action,
    createNormalOrganization: action,
    updateOrganizationUserRole: action,
    logoUpload: observable,
    setCurrentLogoUpload: action,
    getCurrentOrganizationById: action,
    resetStatus: action,

    paramsOrganizationDetail: observable,
    setParamsOrganizationDetail: action,

    showOrganization: observable,
    setShowOrganization: action,

    modalCreateProject: observable,
    setModalCreateProject: action,

    modalEditProject: observable,
    setModalEditProject: action,

    statusProject: observable,
    setStatusProject: action,

    forceReload: observable,
    setForceReload: action,

    isModalVisible: observable,
    setIsModalVisible: action,
    checkInvitationOrganization: action,
    invitationDetail: observable,
    setInvitationDetail: action,
    clearInvitationDetail: action,
    getOrganizationUserBelong: action,
    orgUserBelongList: observable,
    clearOrgUserBelongList: action,
    sendWarningQuotaLicense: action,
    getOrganizationTemplates: action,
    organizationTemplates: observable,
    clearOrganizationTemplate: action,
    setOrganizationTemplate: action

})

export default new OrganizationStore();