import { Button } from 'antd'
import styled, { css } from 'styled-components'

export const TreeDataFolder = styled.div`
  div.treeData-panel div.ant-tree-treenode.empty-file {
    display: none;
    position: fixed;
    z-index: -1;
    top: -10%;
  }
 
  .drag-over .ant-tree-node-content-wrapper, .drag-over.ant-tree-treenode-switcher-close, .drag-over.ant-tree-treenode-switcher-open {
    color:#fff;
    background-color:#f26524;
    opacity:.8
  }
`
