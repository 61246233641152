import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet'
import {
  AuthPageWrapper, FormTitle, FormWrapper, FormSubtitle,
} from './CustomStyled'
import { Link } from 'react-router-dom'
import LoginForm from './LoginForm'
import LoadingSpinner from '../../elements/LoadingSpinner'
import { inject, observer } from 'mobx-react'
import { Typography } from 'antd';
import Iframe from 'react-iframe'
import { SocketContext } from '../../../socket-context';
import projectStore from '../../../stores/projectStore';
import { toJS } from 'mobx'

const AuthPage = ({ match, authStore, commonStore, usersStore }) => {
  //const socket = useContext(SocketContext);
  const handleAuthenticated = async function (msg) {
    const { type, data } = msg

  }
  const xdUrl = `https://prod.xd-twin.io/auth/login?publicKey=${authStore.publicKey}&sesstionId=${authStore.sesstionId}`
  const authType = match.params.authType
  const { Text } = Typography;
  const checkAppLoading = () => {
    return !!(
      commonStore.isLoading ||
      authStore.isLoading ||
      usersStore.isLoading ||
      projectStore.isLoading
    )
  }

  const authFormInput = () => {
    return (
      <Fragment>
        <LoginForm />
      </Fragment>
    )
  }

  const authPageTitle = () => {
    switch (authType) {
      case 'login':
        return 'Login'
      case 'register':
        return 'Register'
      case 'forgot-password':
        return 'Forgot Password'
      default:
        return 'Undefined'
    }
  }

  return (
    <AuthPageWrapper>
      <Helmet>
        <title>{authPageTitle()}</title>
      </Helmet>
      <FormWrapper style={(authType === 'register') ? { marginTop: 150 } : {}}>
        {authFormInput()}
      </FormWrapper>
      <Iframe url={xdUrl}
        width="65%"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative" />
      {
        checkAppLoading()
          ? <LoadingSpinner theme={commonStore.appTheme} type={'page'} />
          : null
      }
    </AuthPageWrapper>
  )

}

export default inject('authStore', 'commonStore', 'usersStore')(observer(AuthPage))