/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { Tree, Empty, Popconfirm, Tooltip, Spin, Avatar } from 'antd'
import { TreeDataFolder } from './CustomStyled'
import SVGIcon from './SVGIcon'
import { ReactComponent as ResourceFolderFoldIcon } from '../../../assets/svgs/resource-folder-fold.svg'
import { ReactComponent as VisibilityOnIcon } from '../../../assets/svgs/visibility-on.svg'
import { ReactComponent as VisibilityOffIcon } from '../../../assets/svgs/visibility-off.svg'
import { ReactComponent as Icon4DOFF } from '../../../assets/svgs/4D-OFF.svg'
import { ReactComponent as Icon4DON } from '../../../assets/svgs/4D-ON.svg'
import { ReactComponent as ClipOn } from '../../../assets/svgs/Clip-plane-ON.svg'
import { ReactComponent as ClipOff } from '../../../assets/svgs/Clip-plane-OFF.svg'

import { ReactComponent as ResourceIfcIcon } from '../../../assets/svgs/resource-ifc-v2.svg'
import { ReactComponent as ResourceXmlIcon } from '../../../assets/svgs/resource-xml-v2.svg'
import { ReactComponent as ResourceWmsIcon } from '../../../assets/svgs/resource-wms.svg'
import { ReactComponent as Resource3DIcon } from '../../../assets/svgs/resource-3d-v2.svg'
import { ReactComponent as Resource3DTilesIcon } from '../../../assets/svgs/resource-3dtiles.svg'
import { ReactComponent as ResourcePointCloudIcon } from '../../../assets/svgs/resource-point-cloud.svg'

import { ReactComponent as VolumeIcon } from '../../../assets/svgs/volume.svg'
import { ReactComponent as LineIcon } from '../../../assets/svgs/line.svg'
import { ReactComponent as AreaIcon } from '../../../assets/svgs/area.svg'
import { ReactComponent as SingleIcon } from '../../../assets/svgs/single.svg'
import { ReactComponent as Mesh3DIcon } from '../../../assets/svgs/3Dmesh.svg'

import DocFile from '../../../assets/form-elems/Resource DOC.png'
import JPGFile from '../../../assets/form-elems/Resource JPG.png'
import PDFFile from '../../../assets/form-elems/Resource PDF.png'
import PNGFile from '../../../assets/form-elems/Resource PNG.png'
import PPTFile from '../../../assets/form-elems/Resource PPT.png'
import TIFFile from '../../../assets/form-elems/Resource TIFF.png'
import TXTFile from '../../../assets/form-elems/Resource TXT.png'
import XLSFile from '../../../assets/form-elems/Resource XLS.png'
import GenericFile from '../../../assets/form-elems/generic-doc.png'

import { ReactComponent as DWG } from '../../../assets/svgs/dwg.svg'
import { ReactComponent as PDF } from '../../../assets/svgs/pdf.svg'
import { ReactComponent as DXF } from '../../../assets/svgs/dxf.svg'
import { ReactComponent as IMG } from '../../../assets/svgs/img.svg'
import { ReactComponent as GENERIC } from '../../../assets/svgs/generic.svg'
// import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import TreeUtils from '../../../tree-utils'
import { toJS } from 'mobx'

const DataTreePanel = ({ projectStore, usersStore, sketchingStore }) => {
  const [treeDataProjectLink, setTreeDataProjectLink] = useState([]);
  const [treeDataModel, setTreeDataModel] = useState([]);
  const [treeDataSketch, setTreeDataSketch] = useState([]);
  const { projectId } = useParams();
  const getSketchLibraryModel = (sketch) => {
    let hasSketchTypeModel = false
    if (sketch) {
      if (!sketch) return false
      if (sketchingStore.sketchLibraryElements && sketch.typeLibrary && sketch.typeLibrary.checkedKeys && sketch.typeLibrary.checkedKeys.length > 0) {
        if (sketchingStore.sketchLibraryElements) {
          let data = sketchingStore.sketchLibraryElements
          let result = []
          let _checkedKey = sketch.typeLibrary.checkedKeys
          _checkedKey.map(key => {
            let node = TreeUtils.searchTreeNode(data, 'key', key);
            if (node) {
              result.push(toJS(node))
            }
          })
          hasSketchTypeModel = result.length > 0 ? true : false
        }
      }
      return hasSketchTypeModel
    }
    return false
  }
  const checkHas4DTimeStamp = (data) => {
    let isShowIcon = true
    if (!data.startDate && !data.endDate) {
      isShowIcon = false
    }
    return isShowIcon
  }
  const setSketchIcon = (item) => {
    let check3dMesh = getSketchLibraryModel(item)
    if (item.type == 'area' && item.readonlyHeight && !check3dMesh) {
      return <AreaIcon />
    }
    else if (item.type == 'area' && !item.readonlyHeight && !check3dMesh) {
      return <VolumeIcon />
    }
    else if (item.type == 'line' && !item.readonlyHeight && !check3dMesh) {
      return <LineIcon />
    }
    else if (item.type == 'point' && !item.readonlyHeight && !check3dMesh) {
      return <SingleIcon />
    }
    else {
      return <Mesh3DIcon />
    }

  }
  const setModelIcon = (item) => {
    switch (item.modelType) {
      case 'landxmlBackground':
      case 'landxml':
        return <ResourceXmlIcon />
      case 'ifc':
        return <ResourceIfcIcon />
      case 'model3d':
      case 'kmz':
        return <Resource3DIcon />
      case 'cloudpoint':
        return <ResourcePointCloudIcon />
    }
    switch (item.sourceType) {
      case 'WMS':
      case 'WFS':
        return <ResourceWmsIcon />
      case 'City3DDB':
        return <ResourceWmsIcon />
      case 'external':
        return <Resource3DTilesIcon />
    }
    const ext = item && item.id && item.ext && item.ext.toLowerCase()

    switch (ext) {
      case '.doc':
      case '.docx':
        return <img src={DocFile} />;
      case '.jpg':
      case '.jpeg':
        return <img src={JPGFile} />;
      case '.pdf':
        return <img src={PDFFile} />
      case '.png':
        return <img src={PNGFile} />
      case '.ppt':
      case '.pptx':
        return <img src={PPTFile} />
      case '.tiff':
        return <img src={TIFFile} />
      case '.txt':
        return <img src={TXTFile} />
      case '.xls':
      case '.xlsx':
        return <img src={XLSFile} />
      default:
        return <img src={GenericFile} />
    }
    let isImage = false
    if (ext && ext.match(/.(jpg|jpeg|png|gif|svg)$/i))
      isImage = true
    if (isImage) {
      return <IMG />
    }
    switch (ext) {
      case '.DWG':
        return <DWG />
      case '.PDF':
        return <PDF />
      case '.DXF':
        return <DXF />
      default:
        return <GENERIC />
    }
  }
  useEffect(() => {
    if (projectStore.listProjectLink || projectStore.modelList || sketchingStore.arrSketches) {
      if (projectStore.listProjectLink?.length > 0) {
        const pTreeProjectLink = traversalTree(projectStore.listProjectLink, 'projectLink');
        setTreeDataProjectLink(pTreeProjectLink)
      }
      if (projectStore.modelList?.length > 0) {
        const pTreeModel = traversalTree(projectStore.modelList, 'model');
        setTreeDataModel(pTreeModel)
      }
      if (sketchingStore.arrSketches?.length > 0) {
        const pTreeSketch = traversalTree(sketchingStore.arrSketches, 'sketch');
        setTreeDataSketch(pTreeSketch)
      }
    }
  }, [projectStore.listProjectLink, projectStore.modelList, sketchingStore.arrSketches])
  const toggleVisibleProjectLink = (projectLink, type) => {
    let params = {
      userId: usersStore.currentUser?.id,
      isVisibleClip: projectLink.isVisibleClip || false,
      isVisible: projectLink.isVisible || false,
      isVisible4D: projectLink.isVisible4D || false
    }
    if (type === 'clip') params.isVisibleClip = !projectLink.isVisibleClip;
    if (type === '4D') params.isVisible4D = !projectLink.isVisible4D;
    if (type === 'show') params.isVisible = !projectLink.isVisible;
    const foundIndex = projectLink.visibleData?.findIndex(x => x.userId === usersStore.currentUser?.id)
    const temp = projectLink.visibleData?.length > 0 ? [...projectLink.visibleData] : [];
    if (foundIndex > -1) {
      temp[foundIndex] = params
    } else {
      temp.push(params)
    }
    let listProjectLink = projectStore.listProjectLink.map(item => {
      if (item.id === projectLink.id) {
        if (type === 'clip') item.isVisibleClip = !projectLink.isVisibleClip;
        if (type === '4D') item.isVisible4D = !projectLink.isVisible4D;
        if (type === 'show') item.isVisible = !projectLink.isVisible;
        item.visibleData = temp
      }
      return item
    })
    projectStore.setListProjectLink(listProjectLink)
    const pTree = traversalTree(listProjectLink, 'projectLink');
    setTreeDataProjectLink(pTree)
  }

  const toggleVisibleModel = (model, type) => {
    let listModel = projectStore.modelList.map(item => {
      if (item.id === model.id) {
        if (type === 'clip') item.isVisibleClip = !model.isVisibleClip;
        if (type === '4D') item.isVisible4D = !model.isVisible4D;
        if (type === 'show') item.isVisible = !model.isVisible;
      }
      return item
    })
    projectStore.setModelList(listModel)
  }

  const toggleVisibleSketch = (sketch, type) => {
    let listSketch = sketchingStore.arrSketches.map(item => {
      if (item.id === sketch.id) {
        if (type === '4D') item.isVisible4D = !sketch.isVisible4D;
        if (type === 'show') item.isVisible = !sketch.isVisible;
      }
      return item
    })
    sketchingStore.setSketches(listSketch)
  }

  const traversalTree = (data, type) => {
    if (type === 'projectLink') {
      return data.map(item => {
        return {
          ...item,
          key: item.id,
          title: item?.link?.name,
          icon: <>
            <SVGIcon
              content={<ResourceFolderFoldIcon />}
              width={20}
              height={20}
            />
            <Avatar
              style={{ marginLeft: '3px' }}
              shape="square"
              icon={<SVGIcon
                className={item.isVisible ? '' : 'hide'}
                content={item.isVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleProjectLink(item, 'show')}
            />
            <Avatar
              shape="square"
              style={{ fontSize: '100%' }}
              icon={<SVGIcon
                className={item.isVisible ? '' : 'hide'}
                content={item.isVisibleClip ? <ClipOn /> : <ClipOff />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleProjectLink(item, 'clip')}
            />
            <Avatar
              shape="square"
              icon={<SVGIcon
                className={item.isVisible ? '' : 'hide'}
                content={item.isVisible4D ? <Icon4DON /> : <Icon4DOFF />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleProjectLink(item, '4D')}
            />
          </>
        }
      });
    } else if (type === 'model') {
      return data.map(model => {
        const { treeData } = projectStore.projectDetail
        let item = TreeUtils.searchTreeNode(treeData, 'modelId', model.id);
        return {
          ...model,
          key: model.modelId,
          title: item.title,
          icon: <>
            <SVGIcon
              content={setModelIcon(item)}
              width={20}
              height={20}
            />
            <Avatar
              style={{ marginLeft: '3px' }}
              shape="square"
              icon={<SVGIcon
                className={model.isVisible ? '' : 'hide'}
                content={model.isVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleModel(model, 'show')}
            />
            <Avatar
              shape="square"
              style={{ fontSize: '100%' }}
              icon={<SVGIcon
                className={model.isVisible ? '' : 'hide'}
                content={model.isVisibleClip ? <ClipOn /> : <ClipOff />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleModel(model, 'clip')}
            />
            {
              checkHas4DTimeStamp(model) && <Avatar
                shape="square"
                icon={<SVGIcon
                  className={model.isVisible ? '' : 'hide'}
                  content={model.isVisible4D ? <Icon4DON /> : <Icon4DOFF />}
                  width={20}
                  height={20}
                />}
                size={20}
                onClick={() => toggleVisibleModel(model, '4D')}
              />
            }

          </>
        }
      });
    } else if (type === 'sketch') {
      return data.map(sketch => {
        const { treeData } = projectStore.projectDetail
        let item = TreeUtils.searchTreeNode(treeData, 'sketchId', sketch.id);
        return {
          ...sketch,
          key: sketch.sketchId,
          title: item.title,
          icon: <>
            <SVGIcon
              content={setSketchIcon(sketch)}
              width={20}
              height={20}
            />
            <Avatar
              style={{ marginLeft: '3px' }}
              shape="square"
              icon={<SVGIcon
                className={sketch.isVisible ? '' : 'hide'}
                content={sketch.isVisible ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleSketch(sketch, 'show')}
            />
            {checkHas4DTimeStamp(sketch) && <Avatar
              shape="square"
              icon={<SVGIcon
                className={sketch.isVisible ? '' : 'hide'}
                content={sketch.isVisible4D ? <Icon4DON /> : <Icon4DOFF />}
                width={20}
                height={20}
              />}
              size={20}
              onClick={() => toggleVisibleSketch(sketch, '4D')}
            />
            }

          </>
        }
      });
    }
  }
  return (
    <>
      <Spin tip={"Loading tree"} spinning={projectStore.treeLoading}>
        {treeDataProjectLink && treeDataProjectLink.length ? (
          <div style={{ fontWeight: 'bold', marginBottom: 5 }}>Project links</div>
        ) : null}
        {treeDataProjectLink && treeDataProjectLink.length ? (
          <TreeDataFolder>
            <Tree
              className="draggable-tree treeDataProjectLink-panel"
              showIcon
              treeData={treeDataProjectLink}
            />
          </TreeDataFolder>
        ) : null}
        <div style={{ fontWeight: 'bold', marginBottom: 5, marginTop: 5 }}>Models</div>
        {treeDataModel && treeDataModel.length ? (
          <TreeDataFolder>
            <Tree
              className="draggable-tree treeDataProjectLink-panel"
              showIcon
              treeData={treeDataModel}
            />
          </TreeDataFolder>
        ) : null}
        <div style={{ fontWeight: 'bold', marginBottom: 5, marginTop: 5 }}>Sketches</div>
        {treeDataSketch && treeDataSketch.length ? (
          <TreeDataFolder>
            <Tree
              className="draggable-tree treeDataProjectLink-panel"
              showIcon
              treeData={treeDataSketch}
            />
          </TreeDataFolder>
        ) : null}
      </Spin>
    </>
  )
}

export default inject('projectStore', 'usersStore', 'sketchingStore')(observer(DataTreePanel))
