import axios from 'axios'
import commonStore from './stores/commonStore'
import { apiUrl } from './config'

const requests = {
  get: (url, header = false, urlstatic = true) => {
    if (header && commonStore.token) {
      return axios({
        method: `get`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${commonStore.token}`,
        },
      })
    }
    if (urlstatic) {
      return axios({
        method: `get`,
        url: `${apiUrl}${url}`,
      })
    }
    return axios({
      method: `get`,
      url: `${url}`,
    })
  },
  post: (url, body, header = false, onUploadProgress) => {
    if (header) {
      if (onUploadProgress) {
        return axios({
          method: `post`,
          url: `${apiUrl}${url}`,
          headers: {
            Authorization: `Bearer ${commonStore.token}`,
          },
          data: body,
          onUploadProgress
        })
      }
      return axios({
        method: `post`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${commonStore.token}`,
        },
        data: body,
      })
    }
    return axios({
      method: `post`,
      url: `${apiUrl}${url}`,
      data: body,
    })
  },
  delete: (url, body = false) => {
    if (body) {
      return axios({
        method: `delete`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${commonStore.token}`,
        },
        data: body,
      })
    }
    return axios({
      method: `delete`,
      url: `${apiUrl}${url}`,
      headers: {
        Authorization: `Bearer ${commonStore.token}`,
      },
    })
  },
  put: (url, body, header = false) => {
    if (header) {
      return axios({
        method: `put`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${commonStore.token}`,
        },
        data: body,
      })
    }
    return axios({
      method: `post`,
      url: `${apiUrl}${url}`,
      data: body,
    })
  },
}

const AuthRequest = {
  login: (username, password) =>
    requests.post(`/xdLogin`, {
      identifier: username,
      password: password,
    })
}

const FileRequest = {
  delete: (fileId) => {
    requests.delete(`/upload/files/${fileId}`, true)
  },
  upload: (body) => requests.post(`/upload`, body, true)
}

const UserRequest = {

  getAllUsers: () => requests.get(`/users`, true),
  getUserById: (id) => requests.get(`/users/${id}`, true),
  updateUser: (id, body) => requests.put(`/users/${id}`, body, true),
  getCurrent: () => requests.get(`/users/me`, true),
  createProfileOnRegister: id =>
    requests.post(
      `/userprofiles`, { fullName: '', user: id }, true,
    ),
  createProfile: body => requests.post(`/userprofiles`, body, true),
  updateProfile: (id, body) => requests.put(`/userprofiles/${id}`, body, true),
  updateProfileMe: (body) => requests.put(`/users/me`, body, true),


  // updateProfiles: body =>
  //   requests.put(`/api/account/update-profile`, body, true),``
  delete: id => requests.delete(`/userprofiles/${id}`, true),
  deleteUser: id => requests.delete(`/users/${id}`, true),
  createUser: body => requests.post(`/users`, body, true),
  customFindUser: (type, body) => requests.post(`/users/custom-find?type=${type}`, body, true)

}

const ProjectRequest = {
  getTotalProjects: () => requests.get(`/projects`, true),
  getAllProjects: () => requests.get(`/projectuserroles`, true),
  getDetail: id => {
    return requests.get(`/projects/${id}`, true)
  },
  uploadModel: (formData, onUploadProgress) => {
    return requests.post(`/upload/`, formData, true, onUploadProgress)
  },
  createModel: data => {
    return requests.post(`/model3ds`, data, true)
  },
  updateModel: (id, body) => requests.put(`/model3ds/${id}`, body, true),
  createProject: data => {
    return requests.post(`/projects`, data, true)
  },
  updateProject: (projectId, body) => requests.put(`/projects/${projectId}`, body, true),
  // getAllModels: (projectId) => {
  //   return requests.get(`/modelsByProject/${projectId}`, true)
  // },
  deleteProject: projectId =>
    requests.delete(`/projects/${projectId}`, true),
  getProjectRoles: () => requests.get(`/projectroles`, true),
  sendProjectInvite: body => requests.post(`/projectuserroles`, body, true),
  createViewPoint: body => requests.post(`/viewpoints`, body, true),
  deleteViewPoint: id => requests.delete(`/viewpoints/${id}`, true),
  updateLocation: (id, body) => requests.put(`/model3ds/${id}`, body, true),
  getModel3DSById: id => requests.get(`/model3ds/${id}`, true),
  findLocation: (address) => requests.get(`/lookaddress?address=${address}`, true),
  acceptProjectInvitation: (projectId, body) =>
    requests.put(`/projectuserroles/${projectId}`, body, true),
  deleteTileset: (modelId, treeNodeKey = undefined) =>
    requests.delete(`/model3ds/${modelId}/${treeNodeKey}`, true),
  updateCaptureName: (captureId, value) =>
    requests.put(`/viewpoints/${captureId}`, { name: value }, true),
  searchProjection: params =>
    requests.get(`https://epsg.io/?format=json&q=${params}`, false, false),
  duplicateProject: (projectId, data) => requests.post(`/project/duplicateProject/${projectId}`, data, true),
}

const OrganizationRequest = {
  getOrganization: () => requests.get(`/organizations`, true),
  getOrganizationById: id => requests.get(`/organizations/${id}`, true),
  getOrganizationBelongUser: () => requests.get(`/organizationuserroles/getOrganizationBelongUser`, true),
  getOrganizationRoles: () => requests.get(`/organizationroles`, true),
  getOrganizationRolesById: id => requests.get(`/organizationroles/${id}`, true),
  getOrganizationUserBelong: () => requests.get(`/organizationuserroles/getOrganizationUserBelong`, true),
  getRoleCurrentUserInOrganization: organizationId => requests.get(`/organizationuserroles/getRoleCurrentUserInOrganization/${organizationId}`, true),
  getOrganizationUserRole: () => requests.get(`/organizationuserroles`, true),
  getOrganizationTemplates: (body) => requests.post(`/getOrganizationTemplates`, body, true),
}

const TokenRequest = {
  getValidToken: (token) => requests.get(`/tokens/${token}`, true),
  
  createToken: body => requests.post(`/tokens`, body, true),
  updateToken: (id, body) => requests.put(`/tokens/${id}`, body, true),
  deleteToken: id => requests.delete(`/tokens/${id}`, true),
}

const LicenseRequest = {
  createLicense: (body) => requests.post(`/licenses`, body, true),
  getLicense: () => requests.get(`/licenses`, true),
  getLicenseById: id => requests.get(`/licenses/${id}`, true),
  deleteLicense: id => requests.delete(`/licenses/${id}`, true),
  updateLicense: (id, body) => requests.put(`/licenses/${id}`, body, true),
  findLicenseByUser: userID => requests.get(`/licenses?user=${userID}`, true),
  getAvailableLicenses: (body) => requests.post(`/licenses/getAvailableLicenses`, body, true),
}

const SketchRequest = {
  createSketch: data => requests.post(`/sketches`, data, true),
  deleteSketch: sketchId => requests.delete(`/sketches/${sketchId}`, true),
  updateSketch: (sketchId, body) => requests.put(`/sketches/${sketchId}`, body, true),
  getSketchByProjectId: (projectId) => requests.get(`/sketches/getSketchByProjectId/${projectId}`, true),
  deleteSketchObject: (body) => requests.post(`/sketches/deleteSketchObject`, body, true),
}

const SketchElementRequest = {
  create: (body) => requests.post(`/sketchelements`, body, true),
  get: (type, prj, org) => requests.get(`/sketchelements${type ? "?type=" + type : ''}${prj ? '&project=' + prj : ''}${org ? '&organization=' + org : ''}`, true),
  getById: id => requests.get(`/sketchelements/${id}`, true),
  delete: id => requests.delete(`/sketchelements/${id}`, true),
  update: (id, body) => requests.put(`/sketchelements/${id}`, body, true),
  mergeSketchLibraryElement: (projectId) => requests.get(`/sketchelements/mergeSketchLibraryElement/${projectId}`, true),
}
const ProjectLinkRequest = {
  getListModelProject: id => requests.get(`/getListModelProject/${id}`, true),
  deleteProjectLink:  (id) => requests.delete(`/projectlinks/${id}`, true),
  updateProjectLink:  (id, body) => requests.put(`/projectlinks/${id}`,body, true),
  getListProjectLink: id => requests.get(`/projectlinks?project=${id}`, true),
  createProjectLink: body => requests.post(`/projectlinks`,body, true)
}
export {
  requests,
  AuthRequest,
  FileRequest,
  UserRequest,
  ProjectRequest,
  TokenRequest,
  LicenseRequest,
  OrganizationRequest,
  SketchElementRequest,
  SketchRequest,
  ProjectLinkRequest
}
