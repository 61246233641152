import uuid from 'uuid'
import { cloneDeep } from 'lodash'
const META_DATA_TYPES = {
  IFC_PROPERTIES: 10,
  IFC_HEADER_INFO: 13,
  IFC_SHOW_TREE: 7,
  LANDXML_ATTRIBUTES: 5
}
const TreeUtils = {
  searchTreeNode(data, key, match) {
    const stack = [];
    data.map(item => stack.push(item));
    while (stack.length > 0) {
      const node = stack.pop();
      if (node[key] === match) {
        return node;
      } else if (node.children) {
        node.children.map(child => stack.push(child))
      }
    }
    return null;
  },
  updateTreeData(data, key, children) {
    return data.map((item) => {
      if (item.key === key) {
        return { ...item, children };
      } else if (item.children) {
        return { ...item, children: TreeUtils.updateTreeData(item.children, key, children) };
      }
      return item;
    });
  },
  fetchAllChildAttr(data) {
    data.map(item => {
      if (item.children) {
        const newChildren = [];
        item.children.map(child => {
          if (child.type === META_DATA_TYPES.LANDXML_ATTRIBUTES) {
            if (child.children) {
              child.children.map(gchild => {
                newChildren.push(gchild);
              })
            }
          } else {
            newChildren.push(child);
          }
        })
        item.children = newChildren;
        TreeUtils.fetchAllChildAttr(item.children);
      }

    })
    return data;
  },
  shortenTree(modelType, data, attrData) {
    if (modelType === 'landxml') {
      data.map(item => {
        if (item.children) {
          const attributes = item.children.find(child => child.type === META_DATA_TYPES.LANDXML_ATTRIBUTES);
          const newChildren = item.children.filter(child => child.type !== META_DATA_TYPES.LANDXML_ATTRIBUTES);
          const childAttr = TreeUtils.fetchAllChildAttr(cloneDeep(newChildren));
          if (attributes && attributes.children && childAttr?.length) {
            attributes.children = [...attributes.children, ...childAttr];
          } else if (attributes && childAttr?.length) {
            attributes.children = childAttr;
          }
          if (item.GUID) {
            attrData.push({
              ...attributes,
              pKey: item.key,
              pTitle: item.title,
              GUID: item.GUID
            });
          }
          item.children = newChildren;
          item.isLeaf = !newChildren.length
          TreeUtils.shortenTree(modelType, item.children, attrData)
        }
      })
    } else if (modelType === 'ifc') {
      data.map(item => {
        const properties = item.children.find(child => child.type === META_DATA_TYPES.IFC_PROPERTIES);
        if (properties && properties.children) {
          properties.children.unshift({
            key: properties.GUID,
            title: `GlobalID = ${properties.GUID || ''}`,
          })
        } else if (properties) {
          properties.children = [{
            key: properties.GUID,
            title: `GlobalID = ${properties.GUID || ''}`,
          }]
        }
        if (item?.children[0]?.type === META_DATA_TYPES.IFC_HEADER_INFO) {
          if (item.GUID) {
            attrData.push({
              ...item,
              pKey: item.key,
              pTitle: item.title
            });
          }
        } else {
          if (properties.GUID) {
            attrData.push({
              ...properties,
              pKey: item.key,
              pTitle: item.title
            });
          }
        }
        if (item.children) {
          const newChildren = [];
          item.children.map(child => {
            if (child.type === META_DATA_TYPES.IFC_SHOW_TREE) {
              newChildren.push(child);
            } else {
              if (child.children) {
                child.children.map(gchild => {
                  if (gchild.type === META_DATA_TYPES.IFC_SHOW_TREE) {
                    newChildren.push(gchild);
                  }
                })
              }
            }
          })
          item.children = newChildren;
          item.isLeaf = !newChildren.length
          TreeUtils.shortenTree(modelType, item.children, attrData)
        }
      })
    }
    return {
      data,
      attrData
    };
  },
  setTitleRoot(type) {
    switch (type) {
      case 5:
        return "Scenario 1"
      case 8:
        return "Not referenced"
      case 9:
        return "IfcProject"
      case 13:
        return "Header Info"
      case 7:
        return "Space Boundaries"
      default:
        return ""
    }
  },
  traversalOTree(data) {
    return data.map(item => {
      const key = uuid()
      if (item.children) {
        return {
          ...item,
          title: item.key ? item.key : TreeUtils.setTitleRoot(item.children[0].type),
          key: item.node ? item.node : key,
          children:
            TreeUtils.traversalOTree(item.children, key),
        };
      }
      return {
        ...item,
        title: item.key,
        key: item.node ? item.node : key,
      };
    });
  }
}

export default TreeUtils
