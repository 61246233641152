import { Drawer, Skeleton, Button } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { SocketContext } from '../../../socket-context'
import DataTreePanel from './DataTreePanel'
const DrawerTilesetExplorer = props => {
    const { projectStore, sketchingStore ,usersStore , authStore } = props
    const socket = useContext(SocketContext);
    const hanldeSubmit = () => {
        let visibleSketches = [];
        let visible4DModels = [];
        let visibleClipModels = [];
        let modelHiden = [];
        let sketch = sketchingStore.arrSketches?.map(item => {
            let data = {
                endDate: item.endDate,
                isVisible: item.isVisible,
                isVisible4D: item.isVisible4D,
                sketchId: item.id,
                startDate: item.startDate,
                type4D: "sketch"
            }
            if (!item.isVisible) {
                visibleSketches.push(data)
            }
            if (!item.isVisible4D) {
                visible4DModels.push(data)
            }
        })
        let model = projectStore.modelList?.map(item => {
            let data = {
                endDate: item.endDate,
                isVisible4D: item.isVisible4D,
                isVisibleClip: item.isVisibleClip,
                modelId: item.id,
                startDate: item.startDate,
                type: item.type,
                isRender: item.isRender,
                sourceType: item.sourceType,
                data: item.data,
                type4D: "model"
            }
            if (!item.isVisible) {
                modelHiden.push(data)
            }
            if (!item.isVisible4D) {
                visible4DModels.push(data)
            }
            if (!item.isVisibleClip) {
                visibleClipModels.push(data)
            }
        })
        let projectLinks = toJS(projectStore.listProjectLink).map(item => {
            let visibleData = []
            if (Array.isArray(item.visibleData))
                visibleData = item.visibleData;
            else
                visibleData = [item.visibleData];
            let data = {
                projectLinkId: item.id,
                visibleData
            }
            return data
        })
        let socketData = {
            visible4DModels,
            visibleClipModels,
            visibleSketches,
            modelHiden,
            projectLinks,
        }

        socket.emit("apiNotification", {
            data: socketData,
            type: 'update-visible',
            apiKey: usersStore.currentUser.apiKey,
            sesstionId: authStore.sesstionId
        });
    }
    return (
        <>
            <Drawer
                className="pageDetailDrawer"
                onClose={() => projectStore.setShowDataProject(false)}
                id="pageDetailDrawer"
                width={400}
                mask={false}
                visible={projectStore.showDataProject}
                title="Data project"
                footer={
                    <div style={{ textAlign: 'right', marginRight: 10 }}>
                        <>
                            <Button style={{ marginRight: 8 }} onClick={() => projectStore.setShowDataProject(false)}>
                                Cancel
                            </Button>
                            <Button style={{ marginRight: 8 }} type="primary" onClick={() => hanldeSubmit()}>
                                Save
                            </Button>
                        </>
                    </div>
                }
                placement="left">
                <DataTreePanel />
            </Drawer>
        </>
    )
}

export default withRouter(
    inject(
        'projectStore',
        'sketchingStore',
        'usersStore',
        'authStore'
    )(observer(DrawerTilesetExplorer))
)
