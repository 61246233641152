import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const IconWrapper = styled.div`
  display: inline-block;
  width: ${props => props.width ? props.width + 'px' : 'initial'}
  height: ${props => props.height ? props.height + 'px' : 'initial'}
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${props => props.color ? props.color : ''};
    }
  }
`

const SVGIcon = props => {
  const {
    content, width, height, color, style, className
  } = props

  if (width && height) {
    return (
      <IconWrapper
        width={width}
        height={height}
        style={style}
        color={color}
        className={className}
      >
        {content}
      </IconWrapper>
    )
  }
  return (
    <IconWrapper
      style={style}
      color={color}
      className={className}
    >
      {content}
    </IconWrapper>
  )
}

SVGIcon.propTypes = {
  content: PropTypes.node.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.string
}

export default SVGIcon
